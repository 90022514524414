import { allstoresValue } from "../constants/constantValues";

export const customItemSort = (list: any) => {
    list = list.sort((a: any, b: any) => {
        // sort inner data based on position dragged
        a.data = a.data.sort((firstele: any, secele: any) => firstele['position'] - secele['position']);
        b.data = b.data.sort((firstele: any, secele: any) => firstele['position'] - secele['position']);

        // sort table data based on product group name
        return a['productGroup'].toLowerCase() > b['productGroup'].toLowerCase() ? 1 : -1
    });
    return list;
}

export const customArraySort = (list: any, field: string) => {
    const sorttedList = list.sort((a: any, b: any) => a[field].toLowerCase() > b[field].toLowerCase() ? 1 : -1);
    return sorttedList;
}

export const removeAllStoresOptn = (storelist: any) => {
    let formattedstreList: any[] = [];

    let storelistClone = JSON.parse(JSON.stringify(storelist));
    storelistClone?.forEach((element: any) => {
        if (element !== allstoresValue.storeId) {
            formattedstreList.push(element);
        }
    });
    return formattedstreList;
}