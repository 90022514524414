import React, { useEffect, useState } from "react";
import DraggableList from "react-draggable-list";
import AdminTemplate from "./recordTemplate";

interface IProps {
  cicData?: any;
  deleteCicItem: (item: object) => void;
  saveCicItem: (item: object) => void;
}

export default function AdminRecords(props: IProps) {
  const { cicData, deleteCicItem, saveCicItem } = props;
  const [listdta, setlistdta] = useState<any[]>([]);

  useEffect(() => {
    setlistdta(cicData.products);
  }, [cicData]);

  const onListChange = (newList: any, indexVal: number) => {
    newList.forEach((element: any, index: number) => {
      element.position = index + 1;
    });
    const spp = [...listdta];
    spp[indexVal].data = newList;
    setlistdta(spp);
  };
  const deleteItem = (item: object) => {
    deleteCicItem(item);
  };

  const updateData = (list: any) => {
    setlistdta(list);
  };

  useEffect(() => {
    let cicArgData = JSON.parse(JSON.stringify(cicData));
    cicArgData.products = listdta;
    saveCicItem(cicArgData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listdta]);

  return (
    <div className="w-full overflow-x-auto">
      <h4 className="font-bold font-[Nunito Sans] text-[#00529F] text-[20px] pb-3">
        Item List
      </h4>
      <div className="bg-white border-t-[2px] border-t-[#00000029]   min-w-[870px] ">
        <div className=" mb-[15px] ">
          <div className="grid grid-cols-12 m-0 font-bold text-[16px] border-b-[1px] border-b-[#00000029]">
            <div className="col-span-2  py-[10px] text-[#707070]  text-center text-lg">
              CIC
            </div>
            <div className="col-span-4  py-[10px] text-[#707070] text-center text-lg">
              Description
            </div>
            <div className="col-span-1 py-[10px] text-[#707070] text-center text-lg">
              Case Size
            </div>
            <div className="col-span-2  py-[10px] text-[#707070] text-center text-lg">
              Pack Size
            </div>
            <div className="col-span-2  py-[10px] text-[#707070] text-center text-lg">
              Presentation Stock
            </div>
            <div className="col-span-1  py-[10px] text-[#707070] pl-[18px]"></div>
          </div>
          {listdta &&
            listdta.map((item: any, indexVal) => {
              return (
                <React.Fragment key={`${item.productGroup}_${cicData?.divId}`}>
                  <div className="border-gray-500 font-[Nunito Sans]">
                    <h1 className="font-bold border-t-[#00000029] border-t-[1px] py-[10px] bg-[#F6F6F6]  text-[16px] text-[#2C2A29B3] pl-5">
                      {item.productGroup}
                    </h1>
                  </div>
                  <div className="zeroMargin  text-[16px] ">
                    <DraggableList
                      itemKey="cic"
                      list={item.data}
                      template={AdminTemplate}
                      onMoveEnd={(newList: any) =>
                        onListChange(newList, indexVal)
                      }
                      padding={0}
                      commonProps={{
                        deleteItem,
                        dataList: listdta,
                        productGroup: item.productGroup,
                        updateData,
                      }}
                    />
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      </div>
    </div>
  );
}
